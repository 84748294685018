import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  myStockListSuccessful,
  myStockByIDSuccessful,
  getVehicleAttendances,
  vehicleAttendancesSuccessful,
  qrCodeactivitySuccessful,
  getQRCodeData,
  getQRCodeStatus,
  getQRcodeactivity,
  setQRCodeactivityParams,
  getMyStockById,
  isModelVisible,
  isRatingModelVisible,
  ratingAddSuccessful,
  commentAddSuccessful,
  stockImageUploadSuccessful,
  isStockImgModelVisible,
  getMyStockList,
  stockImgByIdSuccessful,
  getStockImgById,
  stockLocationImageUploadSuccessful,
  isStockLocationModelVisible,
  QuickShellImagesByIDSuccessful,
} from "./mystockSlice";

import {
  getStockPrice,
  isMiniAmountModelVisible,
  isIncentiveModelVisible
} from "../stockPriceView/stockPriceSlice"

// AUTH related methods
import {
  getList,
  postAdd,
  postDownloadFile,
  getParamsList,
  postEdit,
  postFormData,
} from "../../../helpers/Helper";
import { Modal, message } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import { QRCodeSVG } from "qrcode.react";

function* myStockList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && data[k] !== null && k !== "no_of_pages") {
          if (k == "vehicle__year") {
            params[k] = moment(data[k]).format("YYYY");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/purchase/stock/", params);
    yield put(myStockListSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* myStockById({ payload }) {
  try {
    const response = yield call(getList, "/purchase/" + payload, {});
    yield put(getVehicleAttendances({ purchase: response.id }));
    yield put(getQRCodeData({ purchase: response, type: "check" }));
    yield put(myStockByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* QuickShellImagesById({ payload }) {
  try {
    const response = yield call(getList, "/purchase/quicksellimages/" + payload, {});
    // yield put(getVehicleAttendances({ purchase: response.id }));
    // yield put(getQRCodeData({ purchase: response, type: "check" }));
    yield put(QuickShellImagesByIDSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* vehicleAttendances({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        // params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && data[k] !== null && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/vehicleattendances/",
      params
    );
    yield put(vehicleAttendancesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* QRcodeactivity({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        // params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (
          data[k] !== "" &&
          data[k] !== null &&
          k !== "no_of_pages" &&
          k !== "purchase"
        ) {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/attendance/purchaseqrcode/activity/" + payload.purchase,
      params
    );
    yield put(qrCodeactivitySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getQRCodeGenerate({ payload }) {
  try {
    const response = yield call(postAdd, "/attendance/qrcodeactivity/", {
      purchase_id: payload.purchase.id,
      qractivitytype: payload.qractivitytype,
    });
    if (response.status === 201) {
      yield put(
        getQRCodeData({ purchase: payload.purchase, type: "Generate" })
      );
      yield put(
        setQRCodeactivityParams({
          page: 1,
          page_size: 10,
          purchase: payload.purchase.id,
        })
      );
      yield put(
        getQRcodeactivity({
          page: 1,
          page_size: 10,
          purchase: payload.purchase.id,
        })
      );
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* QRCodeData({ payload }) {
  try {
    const response = yield call(
      getList,
      "/attendance/purchaseqrcode/" + payload.purchase.id,
      {}
    );

    if (payload.type === "Generate") {
      message.open({
        className: "print no-print",
        type: "success",
        content: "QR code Generated successfully",
      });
      Modal.success({
        icon: null,
        className: "print printme",
        content: (
          <div className="hp-text-center ">
            <QRCodeSVG value={response.qrvalue} size={300} />,
            <h5 className="hp-mb-0 hp-font-weight-500">
              Vehicle No: {payload.purchase?.vehicle?.vehicleno}
            </h5>
            <p>Version: {payload.purchase?.vehicle?.version?.name}</p>
            {/* <p>{moment(response.createdon).format("DD-MM-YYYY hh:mm:ss a")}</p> */}
          </div>
        ),
        okButtonProps: { className: "print no-print" },
        okCancel: false,
        okText: <span className="print no-print">Print</span>,
        onOk: () => {
          window.print();
        },
      });
      yield put(getQRCodeStatus(true));
    } else {
      yield put(getQRCodeStatus(true));
    }
  } catch (error) {
    yield put(getQRCodeStatus(false));

    yield put(apiError(error));
  }
}
function* SpecialIncentive({ payload }) {
  let stockPriceParams = yield select((state) => state.stockPrice.stockPriceParams)
  try {
    const response = yield call(
      postEdit,
      "/purchase/specialincentive/" + payload.id,
      payload.obj
    );

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Special Incentive, has been Updated Successfully
            </p>
          </div>
        ),
      });
      yield put(getMyStockById(payload.id));
      yield put(getStockPrice(stockPriceParams));
      yield put(isIncentiveModelVisible(false))
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* MinimumSale({ payload }) {
  let stockPriceParams = yield select((state) => state.stockPrice.stockPriceParams)
  try {
    const response = yield call(
      postEdit,
      "/purchase/minsaleamount/" + payload.id,
      payload.obj
    );

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Minimum Sale, has been Updated Successfully
            </p>
          </div>
        ),
      });
      yield put(getMyStockById(payload.id));
      yield put(getStockPrice(stockPriceParams));
      yield put(isMiniAmountModelVisible(false))
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* AddComments({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/purchase/stockcomment/list/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Comment Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isModelVisible(false));
        yield put(commentAddSuccessful())
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Comment. Please try again!
              </p>
            </div>
          ),
        });
      }
    } 
  } 
  catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    yield put(apiError(error));
  }
}


function* AddRating({ payload }) {
  try {
    if (payload.id == 0) {
      const response = yield call(
        postAdd,
        "/purchase/stockrating/listcreate/",
        payload
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Rating Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(getStockPrice({ page: 1, search: "", page_size: 10 }));
        yield put(isRatingModelVisible(false));
        yield put(ratingAddSuccessful())
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Rating. Please try again!
              </p>
            </div>
          ),
        });
      }
    } 
  } 
  catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.error[0]}
            </p>
          </div>
        ),
      });
    }else{
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    
    yield put(apiError(error));
  }
}


function* StockImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      stockImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* StockLocationImageUpload({ payload }) {
  try {
    const response = yield call(postFormData, "/system/attachment/", {
      file: payload.file,
    });

    yield put(
      stockLocationImageUploadSuccessful({ response, ImageType: payload.imageType })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddStockImg({ payload }) {
  try {
    const response = yield call(
      postEdit,
      "/purchase/stockimage/" + payload.id,
      payload.obj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Stock Images has been Updated Successfully
            </p>
          </div>
        ),
      });
      yield put(getMyStockById(payload.id));
      yield put(isStockImgModelVisible(false));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* AddStockCurrentLocation({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/purchase/stockcurrentlocation",
      payload.obj
    );
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Current Location Updated Successfully
            </p>
          </div>
        ),
      });
      // yield put(getMyStockById(payload.id));
      yield put(isStockLocationModelVisible(false));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* StockImgById({ payload }) {
  try {
    const response = yield call(getList, "/purchase/stockimage/" + payload, {});
    yield put(stockImgByIdSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* HotStockChek({ payload }) {
  let stockPriceParams = yield select((state) => state.stockPrice.stockPriceParams)
  try {
    const response = yield call(postEdit, "/purchase/hotstock/" + payload.id, payload);
    if (response.status === 200) {
      console.log("response", response);
      if(response.data.is_hotstock == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                This vehicle is on offer from now
              </p>
            </div>
          ),
        });
        
      }else{
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                The offer on this vehicle is disabled
              </p>
            </div>
          ),
        });
      }
      yield put(getMyStockById(payload.id));
      yield put(getStockPrice(stockPriceParams));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}


export function* watchGetStockList() {
  yield takeEvery("mystock/getMyStockList", myStockList);
}

export function* watchGetStockById() {
  yield takeEvery("mystock/getMyStockById", myStockById);
}
export function* watchGetQuickShellImagesById() {
  yield takeEvery("mystock/getQuickShellImagesById", QuickShellImagesById);
}
export function* watchGetVehicleAttendances() {
  yield takeEvery("mystock/getVehicleAttendances", vehicleAttendances);
}
export function* watchGetQRcodeactivity() {
  yield takeEvery("mystock/getQRcodeactivity", QRcodeactivity);
}
export function* watchQRCodeGenerate() {
  yield takeEvery("mystock/qrCodeGenerate", getQRCodeGenerate);
}
export function* watchGetQRCodeData() {
  yield takeEvery("mystock/getQRCodeData", QRCodeData);
}
export function* watchUpdateSpecialIncentive() {
  yield takeEvery("mystock/updateSpecialIncentive", SpecialIncentive);
}
export function* watchUpdateMinimumSale() {
  yield takeEvery("mystock/updateMinimumSale", MinimumSale);
}
export function* watchCommentsAdd() {
  yield takeEvery("mystock/commentsAdd", AddComments);
}
export function* watchRatingAdd() {
  yield takeEvery("mystock/ratingAdd", AddRating);
}
export function* watchGetStockImageUpload() {
  yield takeEvery("mystock/getStockImageUpload", StockImageUpload);
}
export function* watchGetStockLocationImageUpload() {
  yield takeEvery("mystock/getStockLocationImageUpload", StockLocationImageUpload);
}
export function* watchStockImgAdd() {
  yield takeEvery("mystock/stockImgAdd", AddStockImg);
}
export function* watchCurrentLocationAdd() {
  yield takeEvery("mystock/currentLocationAdd", AddStockCurrentLocation);
}
export function* watchCheckHotStock() {
  yield takeEvery("mystock/checkHotStock", HotStockChek);
}
function* myStockSaga() {
  yield all([
    fork(watchGetStockList),
    fork(watchGetStockById),
    fork(watchGetVehicleAttendances),
    fork(watchGetQRcodeactivity),
    fork(watchQRCodeGenerate),
    fork(watchGetQRCodeData),
    fork(watchUpdateSpecialIncentive),
    fork(watchUpdateMinimumSale),
    fork(watchCommentsAdd),
    fork(watchRatingAdd),
    fork(watchGetStockImageUpload),
    fork(watchGetStockLocationImageUpload),
    fork(watchStockImgAdd),
    fork(watchCurrentLocationAdd),
    fork(watchCheckHotStock),
    fork(watchGetQuickShellImagesById)
  ]);
}

export default myStockSaga;
