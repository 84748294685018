import { createSlice } from "@reduxjs/toolkit";
import { acos } from "mathjs";
import moment from "moment";
export const vehicleEstimationSlice = createSlice({
  name: "vehicleEstimation",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    model: false,
    drawer: false,
    pdfButton:true,
    apiError: {},
    listCount: 0,
    expensesList: [],
    expensesParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    estimationData: {},
    vehicleVersion: {},
    vehicleEstimationList: [],
    vehicleEstimationParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    vehicleEstimationView: {},
    itemsAddData: {},

    // item list
    itemsList: [],
    itemCount: 0,
    itemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    selectedRowKeys: [],
    newSelectedItems: [],
    previewFile: {},
    previewBackFile: {},
    ShowSubmit: false,
    approvedata: {},
    categorys: [],
    seen_categorys: [],
    grand_total: 0,

    vehicleEstPendingList: [],
    vehicleEstPendingParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    pendingCount: 0,
    // cumulativeEstData: {
    //   details:[],
    //   items: [],
    //   vehicle_estimation: [],
    // }, 
    cumulativeEstData: [],
    vehicleYear: {},
    addEstLoading: false,
    filterStatus: false,
  },
  reducers: {
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        vehicleEstimationList: [],
        vehicleEstimationParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        selectedRowKeys: [],
        newSelectedItems: [],
        previewFile: {},
        previewBackFile: {},
        estimationData: {},
        vehicleVersion: {},
        ShowSubmit: false,
      };
    },
    setUploadImage: (state, action) => {
      return {
        ...state,
        estimationData: {
          file: action.payload.file,
          imageUrl: action.payload.imageUrl,
          loading: action.payload.loading
        },
      };
    },

    // get Vehicle Estimation
    getVehicleEstimation: (state, action) => {
      if (state.vehicleEstimationParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehicleEstimationList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehicleEstimationListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.vehicleEstimationParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.vehicleEstimationParams?.page_size
      );
      return {
        ...state,
        vehicleEstimationList: list,
        listCount: action.payload.count,
        loading: false,
        vehicleEstimationParams: {
          ...state.vehicleEstimationParams,
          no_of_pages: noofpages,
        },
      };
    },
    setVehicleEstimationParams: (state, action) => {
      return {
        ...state,
        vehicleEstimationParams: action.payload,
      };
    },

    getVehicleEstimationById: (state) => {
      return {
        ...state,
        loading: true,
        pdfButton:true,
        estimationData: {},
      };
    },
    vehicleEstimationByIdSuccessful: (state, action) => {
      // var numberOfItems = state.numberOfItems
      // var paymentItems = action.payload.response.dailypaymentitems?.map((item, i) => {
      //   numberOfItems += 1;
      //   return {
      //     _id: numberOfItems,
      //     id: item.id,
      //     dailypaymenthead_id: item?.id,
      //     amount: item.amount,
      //     remarks: item.remarks,
      //     dodelete: false,
      //     name: item.dailypaymenthead?.name,
      //     attachment: item.attachment,
      //   };
      // });
      return {
        ...state,
        loading: false,
        pdfButton:false,
        // numberOfItems: numberOfItems,
        // estimationData: {
        //   id: action.payload.response.id,
        //   counter_id: action.payload.response.counter?.id,
        //   date:
        //   action.payload.response.date == null
        //     ? null
        //     : moment(action.payload.response.date),
        //   dailypaymentitems: paymentItems,
        // },
        // selectedRowKeys: paymentItems,
        vehicleEstimationView: action.payload.response,
      };
    },


    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        vehicleEstimationParams: {
          ...state.vehicleEstimationParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        estimationData: {
          ...state.estimationData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    addVehilceEstimation: (state, action) => {
      return {
        ...state,
        addEstLoading: true,
      };
    },
    addVehicleEstSuccessful: (state, action) => {
      return {
        ...state,
        addEstLoading: false,
        grand_total: 0

      }
    },
    isModelVisible: (state, action) => {
      // if (!action.payload) {
      //   return {
      //     ...state,
      //     model: action.payload.status,
      //     itemsAddData: {
      //       category: action.payload.category,
      //       subcategory: action.payload.subcategory,
      //       item_models__model__name: action.payload.item_models__model__name
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,
      //     model: action.payload.status,
      //     // itemsAddData: {
      //     //   user_id: action.payload.user_id,
      //     //   metal_id: action.payload.metal?.id,
      //     //   location: action.payload.scrip?.location_id
      //     // },
      //   };
      // }
      if (action.payload) {
        return {
          ...state,
          selectedRowKeys: [],
          model: action.payload,
        };
      } else {
        return {
          ...state,
          model: action.payload,
          selectedRowKeys: [],
          itemsList: [],
          itemsParams: {
            no_of_pages: 0,
            page_size: 20,
            page: 1,
            search: "",
          },
        };
      }
    },

    getItemsList: (state, action) => {
      return {
        ...state,
        loading: true,
        listCount: 0,
      };
    },
    itemsListSuccessful: (state, action) => {
      // var noofpages = Math.ceil(
      //   action.payload.response.count / state.itemsParams.page_size
      // );

      // return {
      //   ...state,
      //   itemCount: action.payload.response.count,
      //   itemsList: action.payload.response.results,
      //   loading: false,
      //   model: true,
      //   itemsParams: {
      //     ...state.itemsParams,
      //     no_of_pages: noofpages,
      //   },
      // };

      var response = action.payload;
      var newList = [];
      var selectedItemsList = state.newSelectedItems.filter(
        (item) => !item.dodelete
      );
      var selectItems_ids = selectedItemsList.map((i) => {
        return i._id;
      });
      newList = response.results.filter((item) => {
        return selectItems_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.itemsParams?.page == 1) {
        list = newList;
      } else {
        list = [...state.itemsList, ...newList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.itemsParams?.page_size
      );
      return {
        ...state,
        itemsList: list,
        loading: false,
        model: true,
        selectedRowKeys: [],
        itemsParams: {
          ...state.itemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetItemsParams: (state, action) => {
      return {
        ...state,
        itemsParams: action.payload,
      };
    },



    getVehicleVersion: (state, action) => {
      return {
        ...state,
        loading: true,
        vehicleVersion: action.payload,
      };
    },



    setSelectedRow: (state, action) => {
      return {
        ...state,
        loading: false,
        // selectedRowKeys: [...state.selectedRowKeys, ...action.payload],
        selectedRowKeys: [...action.payload],
      };
    },

    addItem: (state, action) => {
      console.log(action.payload)
      let cat_total = 0;
      var category_id = state.itemsParams.category;
      var itemsss = state.selectedRowKeys.map((item, i) => {
        cat_total += parseFloat(item.price);
        return {
          _id: item.id,
          item_id: item.id,
          name: item.name,
          image: item.image,
          category_id: item.category,
          subcategory_id: item.subcategory,
          price: item.price,
          new_price: item.price,
          quantity: 1,
          description: "",
          damage_estimation: "",
          category_price: "",
          dodelete: false,
          checkedItem: false,
        };
      });

      var n_categorys = state.categorys.map((category) => {
        if (category.id == category_id) return { ...category, 'total': category.total + cat_total }
        return category
      })
      const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);
      return {
        ...state,
        newSelectedItems: [...state.newSelectedItems, ...itemsss],
        selectedRowKeys: [],
        categorys: n_categorys,
        grand_total: grand_total,
        model: false,
      };
    },

    removeItem: (state, action) => {
      var selectedRow = [];
      var category_id = action.payload.category_id.id
      if (
        action.payload.id == 0 ||
        action.payload.id == null ||
        action.payload.id == undefined
      ) {
        selectedRow = state.newSelectedItems.filter((item) => {
          return item._id != action.payload._id;
        });
      } else {
        selectedRow = state.newSelectedItems.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }

      const cat_total = selectedRow?.filter((i) => category_id == i.category_id.id && !i.dodelete)?.reduce((total, i) => {
        return total + parseFloat(i.new_price)
      }, 0);

      var n_categorys = state.categorys.map((category) => {
        if (category.id == category_id) return { ...category, 'total': cat_total }
        return category
      })
      // console.log("n_categorys", n_categorys);
      const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);

      return {
        ...state,
        newSelectedItems: selectedRow,
        categorys: n_categorys,
        grand_total: grand_total
      };
    },

    SetCategorys: (state, action) => {
      var n_categorys = action.payload
      n_categorys = n_categorys.map((cat) => {
        return { ...cat, total: 0 }
      })

      return {
        ...state,
        categorys: n_categorys,
      };
    },
    ItemQtyChangeValue: (state, action) => {
      var newQuantity = action.payload.value;
      var qty = newQuantity && newQuantity != '' ? newQuantity : 0
      var row = action.payload.item;
      var selectedItem = state.newSelectedItems.map((item) => {
        if (item._id == action.payload.item._id) {
          return {
            ...item,
            'quantity': newQuantity,
            'new_price': qty ? qty * row.price : 0,
          };
        }
        return item;
      });

      const cat_total = selectedItem?.filter((i) => row.category_id.id == i.category_id.id && !i.dodelete)?.reduce((total, i) => {
        // console.log(total , parseFloat(i.new_price), total + parseFloat(i.new_price));
        return total + parseFloat(i.new_price)
      }, 0);

      var n_categorys = state.categorys.map((category) => {
        if (category.id == row.category_id.id) return { ...category, 'total': cat_total }
        return category
      })
      // console.log("n_categorys", n_categorys);
      const grand_total = n_categorys?.reduce((total, cat) => total + cat.total, 0);
      // console.log("grand_total = ", grand_total)
      return {
        ...state,
        estimationData: {
          ...state.estimationData
        },
        newSelectedItems: selectedItem,
        categorys: n_categorys,
        grand_total: grand_total
      };
    },
    ItemInputChangeValue: (state, action) => {
      var selectedItem = state.newSelectedItems.map((item) => {
        if (item._id == action.payload.item._id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        estimationData: {
          ...state.estimationData
        },
        newSelectedItems: selectedItem,
      };
    },

    addImageUpload: (state, action) => {
      return {
        ...state,
        imageLooading: true,
        // vehicleImg: [...state.vehicleImg, action.payload],
        // estimationData: {...state.estimationData,   ...action.payload}
        estimationData: {
          ...state.estimationData,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },

    setPreviewImg: (state, action) => {
      return {
        ...state,
        loading: false,
        estimationData: {
          ...state.estimationData,
          previewFile: action.payload,
        }
      }
    },

    setPreviewBackImg: (state, action) => {
      return {
        ...state,
        loading: false,
        estimationData: {
          ...state.estimationData,
          previewBackFile: action.payload,
        }
      }
    },

    approveVehicle: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    setShowSubmit: (state, action) => {
      var clearData = action.payload.clearData
      if (clearData) {
        return {
          ...state,
          seen_categorys: [],
          ShowSubmit: false
        };
      } else {
        var n_seen_categorys = Array.from(new Set([...state.seen_categorys, action.payload.cat_id]));
        var n_categorys_ids = Array.from(new Set(state.categorys.map(category => category.id)));
        // console.log("n_seen_categorys, n_categorys_ids, ", n_seen_categorys, n_categorys_ids, n_categorys_ids.length , n_seen_categorys.length , n_seen_categorys.length != 0 , ! clearData)
        return {
          ...state,
          seen_categorys: n_seen_categorys,
          ShowSubmit: n_categorys_ids.length == n_seen_categorys.length && n_seen_categorys.length != 0
        };
      }
    },
    ApproveInputChangeValue: (state, action) => {
      return {
        ...state,
        approvedata: {
          ...state.approvedata,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    // vehicle Estimation Pending list
    getVehicleEstPending: (state, action) => {
      if (state.vehicleEstPendingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehicleEstPendingList: [],
          // cumulativeEstData:{},
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehicleEstPendingListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.vehicleEstPendingParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.vehicleEstPendingParams?.page_size
      );
      return {
        ...state,
        vehicleEstPendingList: list,
        pendingCount: action.payload.count,
        loading: false,
        vehicleEstPendingParams: {
          ...state.vehicleEstPendingParams,
          no_of_pages: noofpages,
        },
      };
    },
    setVehicleEstPendingParams: (state, action) => {
      return {
        ...state,
        vehicleEstPendingParams: action.payload,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
    getEstCumuluativeItems: (state, action) => {
      return {
        ...state,
        loading: true,
        // rowdata: {},
      };

    },
    estiCumulativeItemSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        // cumulativeEstData: {
        //   details: action.payload.response.details,
        //   items: action.payload.response.items,
        //   vehicle_estimation: action.payload.response.vehicle_estimations,
        // }
        cumulativeEstData: action.payload.response.results
      }
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  isDrawerVisible,
  FilterInputChangeValue,
  ItemInputChangeValue,
  ItemQtyChangeValue,
  SetCategorys,
  getVehicleEstimation,
  vehicleEstimationListSuccessful,
  setVehicleEstimationParams,
  addExpenseItem,
  isModelVisible,
  removeItem,
  addVehilceEstimation,
  InputChangeValue,
  getVehicleEstimationById,
  vehicleEstimationByIdSuccessful,
  apiError,
  getVehicleVersion,
  getItemsList,
  itemsListSuccessful,
  SetItemsParams,
  setSelectedRowKeys,
  setSelectedRow,
  setUploadImage,
  addItem,
  addImageUpload,
  setPreviewImg,
  setPreviewBackImg,
  approveVehicle,
  setShowSubmit,
  ApproveInputChangeValue,

  getVehicleEstPending,
  vehicleEstPendingListSuccessful,
  setVehicleEstPendingParams,
  getEstCumuluativeItems,
  estiCumulativeItemSuccessful,
  addVehicleEstSuccessful,
  checkFilterStatus
} = vehicleEstimationSlice.actions;

export default vehicleEstimationSlice.reducer;
